import * as React from 'react'
import styled from '@emotion/styled'
import { TrackTraceQuery } from '../../generated-models'
import { Icon } from '../ui'
import { useThemeUI } from 'theme-ui'

const Wrapper = styled.div`
  height: 500px;
  min-width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    height: 300px;
  }
`

const SlideContainer = styled.div<{ position: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(calc(-100% * ${({ position }) => position}));
  z-index: -1000;
`

const CarImage = styled.div`
  flex: 1 0 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`

const ArrowRight = styled.div`
  align-self: flex-end;
  align-items: center;
  display: flex;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-right: 10px;
  }
`

const BannerSlider: React.SFC<TrackTraceQuery['tracktrace']['car']> = (car) => {
  const [slidePosition, setSlidePosition] = React.useState(0)
  const [isMobile, setIsMobile] = React.useState(false)
  const { theme } = useThemeUI() as any
  const slideNext = () => {
    if (slidePosition !== car.images.length - 1) {
      setSlidePosition(slidePosition + 1)
    } else {
      setSlidePosition(0)
    }
  }
  React.useEffect(() => {
    const handler = () => setIsMobile(window.innerWidth < theme.breakpoints[0])
    handler()
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  })
  return (
    <Wrapper>
      <SlideContainer position={slidePosition}>
        {car.images.map((image) => (
          <CarImage
            key={image.id}
            style={{ backgroundImage: `url(${image?.location})` }}
          />
        ))}
      </SlideContainer>
      <ArrowRight>
        <Icon
          onClick={slideNext}
          color="white"
          icon="arrow-right"
          size={isMobile ? 40 : 100}
        />
      </ArrowRight>
    </Wrapper>
  )
}

export default BannerSlider
