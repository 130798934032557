import React from 'react'
import { useTrackTraceQuery } from '../generated-models'
import { Flex, Box, Text } from 'rebass'
import { Spacing } from '../components/ui'
import Messages from '../components/TrackTrace/Messages'
import { Router, RouteComponentProps } from '@reach/router'
import TrackTraceLogin from 'components/TrackTrace/TrackTraceLogin'
import TrackTraceProgress from 'components/TrackTrace/TrackTraceProgress'
import BannerSlider from 'components/TrackTrace/BannerSlider'
import TrackTraceDetails from 'components/TrackTrace/TrackTraceDetails'
import useMode from 'utils/useMode'
import { Helmet } from 'react-helmet'
import Layout from '@/layouts'

const TrackTrace: React.SFC<RouteComponentProps<{
  orderNumber: string
}>> = (props) => {
  const { orderNumber } = props
  const [, setMode] = useMode()
  setMode('dark')
  const { loading, error, data, refetch } = useTrackTraceQuery({
    variables: { orderNumber },
  })
  if (loading) return <div>Loading...</div>
  if (error)
    return <TrackTraceLogin orderNumber={orderNumber} refetch={refetch} />
  return (
    <Layout>
      <Helmet
        title="Track & Trace"
        meta={[{ name: 'robots', content: 'noindex' }]}
      />

      <TrackTraceProgress {...data.tracktrace} />
      <BannerSlider {...data.tracktrace.car} />
      <Spacing space={20} />
      <Box variant="container">
        <Flex>
          <Box width={[1, 1 / 2]}>
            <Text variant="">Details</Text>
            <Spacing space={20} />
            <TrackTraceDetails {...data.tracktrace} />
          </Box>
          <Box width={[1, 1 / 2]}>
            <h3>Berichten</h3>
            <Spacing space={20} />
            <Messages orderNumber={orderNumber} {...data.tracktrace} />
          </Box>
        </Flex>
      </Box>
    </Layout>
  )
}

const TrackTraceWithRouter = () => (
  <Router>
    <TrackTrace path="/tracktrace/:orderNumber" />
  </Router>
)

export default TrackTraceWithRouter
