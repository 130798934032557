import * as React from 'react'
import { ChatFeed, Message } from 'react-chat-ui'
import {
  TrackTraceQuery,
  useSendClientTrackTraceMessageMutation,
} from '../../generated-models'
import { Formik, Field, FieldProps } from 'formik'
import { TextArea, Button } from '../ui'
import * as Yup from 'yup'
import styled from '@emotion/styled'

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .min(10, 'gebruik minimaal 10 karakters')
    .required('Dit veld is verplicht'),
})

const MessageInputWrapper = styled.div`
  border: 1px solid #afafaf;
  border-radius: 10px;
  padding: 0 10px;
`

const Messages: React.SFC<{
  orderNumber: string
} & TrackTraceQuery['tracktrace']> = props => {
  const messages =
    props.messages &&
    props.messages.map(
      ({ client, user, content }) =>
        new Message({
          id: client ? 0 : user.id,
          message: content,
          senderName: user && user.name,
        })
    )

  const [sendMessage] = useSendClientTrackTraceMessageMutation()
  return (
    <>
      <ChatFeed
        hasInputField={false}
        messages={messages || []}
        showSenderName
      />
      <Formik
        initialValues={{ message: '' }}
        validationSchema={validationSchema}
        onSubmit={async ({ message }, actions) => {
          try {
            await sendMessage({
              variables: { orderNumber: props.orderNumber, message },
            })
            actions.resetForm()
          } catch (e) {
            actions.setErrors({ message: 'Er ging wat fout' })
          }
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <MessageInputWrapper>
              <TextArea
                name="message"
                fieldProps={{ icon: 'message' }}
                placeholder="Bericht"
              />
            </MessageInputWrapper>
            <Button type="submit">Verstuur</Button>
          </form>
        )}
      />
    </>
  )
}

export default Messages
