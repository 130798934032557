import React from 'react'
import styled from '@emotion/styled'
import { TrackTraceQuery } from '../../generated-models'
import get from 'lodash/get'
import find from 'lodash/find'
import {
  interiorTypes,
  paintTypes,
  transmissionTypes,
  fuelTypes,
} from '../../constants'

const Table = styled.table`
  width: 100%;
  tr:nth-child(even) {
    background-color: rgb(230, 230, 230);
  }
`

const Td = styled.td`
  line-height: 1.5;
`

const Tr = styled.tr`
  & > td {
    &:not(:first-child) {
      text-align: right;
      padding-left: 20px;
    }
  }
`

const checkIfLabelExists = (
  constantArr: { value: string; label: string }[],
  value: string
) => {
  const foundValue = find(constantArr, { value })
  if (foundValue && foundValue) return foundValue.label
  return ''
}

const carDetailsToShow: {
  key?: string
  label: string
  prefix?: string
  suffix?: string
  render?: (car: TrackTraceQuery['tracktrace']['car']) => string
}[] = [
  {
    key: 'brand.name',
    label: 'Merk',
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'version',
    label: 'Uitvoering',
  },
  {
    key: 'co2',
    label: 'CO2 uitstoot',
    suffix: ' g/km',
  },
  {
    render: car => checkIfLabelExists(fuelTypes, car.fuelType),
    label: 'Brandstof',
  },
  {
    render: car =>
      car.interiorColor &&
      car.interiorType &&
      `${car.interiorColor} ${checkIfLabelExists(
        interiorTypes,
        car.interiorType
      )}`,
    label: 'Interieur',
  },
  {
    render: car =>
      car.paint &&
      car.color &&
      `${checkIfLabelExists(paintTypes, car.paint)} ${car.color}`,
    label: 'Kleur',
  },
  {
    render: car => checkIfLabelExists(transmissionTypes, car.transmission),
    label: 'Transmissie',
  },
  {
    key: 'year',
    label: 'Bouwjaar',
  },
  {
    key: 'power',
    label: 'Vermogen',
    suffix: 'pk',
  },
  {
    key: 'displacement',
    label: 'Motor inhoud',
    suffix: 'L',
  },
]

const TrackTraceDetails: React.SFC<TrackTraceQuery['tracktrace']> = data => {
  return (
    <Table>
      <tbody>
        {carDetailsToShow.map(
          (detail, i) =>
            ((detail.render && detail.render(data.car)) ||
              get(data.car, detail.key)) &&
            detail.label && (
              <Tr key={i}>
                <Td>{detail.label}</Td>
                <Td>
                  {detail.prefix}
                  {detail.render
                    ? detail.render(data.car)
                    : get(data.car, detail.key)}
                  {detail.suffix}
                </Td>
              </Tr>
            )
        )}
      </tbody>
    </Table>
  )
}

export default TrackTraceDetails
