import React, { Suspense } from 'react'
import styled from '@emotion/styled'
import { TrackTraceQuery, OrderStatus } from '../../generated-models'
import OnScreen from 'react-on-screen'
import format from 'date-fns/format'
import Lottie from 'react-lottie'
import CheckedDone from '../../lottie/checked-done.json'

const ProgressBarWrapper = styled.div`
  overflow: hidden;
`

const ProgressBarContainer = styled.div<{
  isVisible?: boolean
  activeIndex: number
}>`
  display: flex;
  margin-top: 12px;
  transition: transform 1s ease-in-out;
  ${({ isVisible, activeIndex }) =>
    isVisible &&
    `
    transform: translateX(calc(-50% * ${activeIndex - 1} - 22%))
  `}
`

const CurrentStepInfoWrapper = styled.div<{ isVisible: boolean }>`
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in 0.8s;
  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
  `}
  display: flex;
  justify-content: center;
`

const CurrentStepInfoContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  background: white;
  box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.2);
  margin: 30px 0;
  border-radius: 10px;
  padding: 10px;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
  svg {
    width: 150px;
    height: 150px;
    padding: 10px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -20px;
    margin-bottom: -20px;
  }
`

const TimeStamp = styled.div`
  background-color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  color: black;
  padding: 0 5px;
  & > span {
    font-size: 16px;
    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      font-size: 10px;
    }
  }
`

const StatusStep = styled.div<any>`
  position: relative;
  height: 200px;
  display: flex;
  flex: 1 0 56%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: -6%;
  background: linear-gradient(
    to right,
    ${({ theme }) => `${theme.colors.primary}, ${theme.colors.secondary}`}
  );
  clip-path: polygon(0 -12px, 90% -12px, 100% 50%, 90% 100%, 0% 100%);
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: 100px;
  }
  ${TimeStamp} {
    &:nth-child (4) {
      margin-top: 10px;
    }
  }
`

const StepPointer = styled.div<{ activeIndex: number; index: number }>`
  position: absolute;
  top: -3px;
  height: 20px;
  width: 20px;
  transform: translateY(-8px);
  z-index: 1000;
  border-radius: 50%;
  ${({ activeIndex, index }) => {
    if (activeIndex + 1 >= index)
      return `
      background-color: #ff2374;
    `
    return `
      background-color: grey; 
    `
  }}
`

const TopBorder = styled.div<{ activeIndex: number; index: number }>`
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    right: 50%;
    ${({ activeIndex, index }) => {
      if (activeIndex + 1 >= index)
        return `
        outline: solid 2px #ff2374;
      `
      return `
        outline: dashed 2px grey;
      `
    }}
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: -2px;
    right: 0;
    ${({ activeIndex, index }) => {
      if (activeIndex + 1 <= index)
        return `
        outline: dashed 2px grey;
      `
      return `
        outline: solid 2px #ff2374;
      `
    }}
  }
`

const StatusText = styled.span`
  font-size: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size: 12px;
  }
`

interface Status {
  label: string
  icon?: Promise<any>
  lottie?: any
}

const statusses: { [name: string]: Status } = {
  default: {
    label: 'Wachten op betaling',
    icon: import('@/images/paymentpending.svg'),
  },
  [OrderStatus.PaymentReceived]: {
    label: 'Betaling ontvangen',
    icon: import('@/images/paymentreceived.svg'),
  },
  [OrderStatus.TechnicalExamination]: {
    label: 'Technische Keuring',
    icon: import('@/images/notepad.svg'),
  },
  [OrderStatus.InTransit]: {
    label: 'Onderweg naar Nederland',
    icon: import('@/images/intransittonl.svg'),
  },
  [OrderStatus.Arrived]: {
    label: 'Gearriveerd in Nederland',
    icon: import('@/images/arrivedinnl.svg'),
  },
  [OrderStatus.RdwExamination]: {
    label: 'RDW Keuring',
    icon: import('@/images/rdw.svg'),
  },
  [OrderStatus.Accessoiries]: {
    label: 'Eventuele accesoires',
    icon: import('@/images/accessoires.svg'),
  },
  [OrderStatus.ReceivedLicensePlate]: {
    label: 'Kentekenplaat ontvangen',
    icon: import('@/images/receivedlicenseplate.svg'),
  },
  [OrderStatus.InTransitToYou]: {
    label: 'Onderweg naar jou',
    icon: import('@/images/home.svg'),
  },
  [OrderStatus.Completed]: {
    label: 'Afgehandeld',
    icon: import('@/images/finished.svg'),
    lottie: CheckedDone,
  },
}

const TrackTraceProgress: React.SFC<TrackTraceQuery['tracktrace']> = ({
  status,
  statusLogs,
}) => {
  const activeIndex = () => {
    const index = Object.keys(statusses).findIndex((i) => i === status)
    if (index !== -1) return index
    return 0
  }
  const activeStep = Object.entries(statusses)[activeIndex()]
  return (
    <>
      <ProgressBarWrapper>
        <OnScreen once>
          {({ isVisible }) => (
            <>
              <CurrentStepInfoWrapper isVisible={isVisible}>
                <CurrentStepInfoContent>
                  {activeStep[1].lottie ? (
                    React.createElement(() => {
                      const [LottieState, setLottieState] = React.useState({
                        isStopped: true,
                        isPaused: true,
                      })
                      React.useEffect(() => {
                        const timer = setTimeout(() => play(), 800)
                        return () => {
                          clearTimeout(timer)
                        }
                      }, [])
                      const play = () =>
                        setLottieState({
                          isPaused: false,
                          isStopped: false,
                        })
                      return (
                        <Lottie
                          {...LottieState}
                          options={{
                            autoplay: false,
                            loop: false,
                            animationData: activeStep[1].lottie,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                        />
                      )
                    })
                  ) : (
                    <Suspense fallback={null}>
                      {React.createElement(
                        React.lazy(() =>
                          activeStep[1].icon.then((res) => ({
                            default: res.ReactComponent,
                          }))
                        )
                      )}
                    </Suspense>
                  )}
                </CurrentStepInfoContent>
              </CurrentStepInfoWrapper>
              <ProgressBarContainer
                isVisible={isVisible}
                activeIndex={activeIndex()}
              >
                {Object.entries(statusses).map((status, i) => (
                  <Status
                    key={status[0]}
                    statusLog={statusLogs.filter(
                      (log) => log.status === status[0]
                    )}
                    activeIndex={activeIndex()}
                    index={i + 1}
                    {...status[1]}
                  />
                ))}
              </ProgressBarContainer>
            </>
          )}
        </OnScreen>
      </ProgressBarWrapper>
    </>
  )
}

interface StatusProps extends Status {
  index: number
  activeIndex: number
  statusLog: TrackTraceQuery['tracktrace']['statusLogs']
}

const Status: React.SFC<StatusProps> = ({
  label,
  index,
  activeIndex,
  statusLog,
}) => {
  return (
    <StatusStep>
      <TopBorder activeIndex={activeIndex} index={index} />
      <StepPointer activeIndex={activeIndex} index={index} />
      <StatusText>
        {index}. {label}
      </StatusText>
      {statusLog && statusLog.length > 0 && (
        <TimeStamp>
          <span>
            {format(new Date(statusLog[0].createdAt), 'dd/MM/yyyy HH:mm')}
          </span>
        </TimeStamp>
      )}
    </StatusStep>
  )
}

export default TrackTraceProgress
