import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Box, Text, Flex } from 'rebass'
import { Button, TextInput } from '../ui'
import { ApolloQueryResult } from 'apollo-client'
import {
  TrackTraceQueryVariables,
  TrackTraceQuery,
} from '../../generated-models'
import { useTrackTraceLoginMutation } from 'generated-models'

interface TrackTraceLoginProps {
  orderNumber: string
  refetch: (
    variables?: TrackTraceQueryVariables
  ) => Promise<ApolloQueryResult<TrackTraceQuery>>
}

const TrackTraceLogin: React.SFC<TrackTraceLoginProps> = ({
  orderNumber,
  refetch,
}) => {
  const [trackTraceLogin] = useTrackTraceLoginMutation()
  return (
    <Flex height="100vh" alignItems="center" justifyContent="center" p={4}>
      <Box variant="content" maxWidth={400} width="100%">
        <Text>Voer uw email in</Text>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Voer een geldige email in.')
              .required('Voer een emailadres in.'),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const res = await trackTraceLogin({
                variables: { orderNumber: orderNumber, email: values.email },
              })
              if (res) {
                refetch({ orderNumber })
              }
              throw new Error('Backend did not respond')
            } catch (e) {
              setErrors({ email: 'Er is iets mis gegaan.' })
            }
            setSubmitting(false)
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <TextInput
                fieldProps={{ icon: 'mail', color: 'white' }}
                placeholder="Email"
                type="email"
                name="email"
              />
              <Button type="submit">Ga door</Button>
            </form>
          )}
        />
      </Box>
    </Flex>
  )
}

export default TrackTraceLogin
